import React from 'react';
import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';
import IconInstagram from '../components/IconInstagram';

import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';

const AboutPage = styled.main``;

const AboutHeader = styled.section`
	display: flex;
	flex-direction: column-reverse;
	border-bottom: 1px solid;

	@media (min-width: 1024px) {
		flex-direction: row;

		height: calc(100vh - 226px);
	}
`;

const MissionStatement = styled.aside`
	padding: 50px 20px;

	@media (min-width: 1024px) {
		padding: 50px;
		flex-basis: 50%;
		border-left: 1px solid;
	}
`;

const AboutImg = styled.aside`
	border-bottom: 1px solid;
	width: 100%;

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		display: block;
	}

	@media (min-width: 1024px) {
		flex-basis: 50%;
		border-bottom: none;
	}
`;

const Statement = styled.h2`
	font-size: 32px;
	@media (min-width: 1024px) {
		font-size: 54px;
	}
	@media (min-width: 1200px) {
		font-size: 64px;
	}
`

const CoreValues = styled.section`
	border-bottom: 1px solid;
	@media (min-width: 1024px) {
		display: flex;
	}
`;

const Label = styled.h3`
	font-size: 24px;
	padding-bottom: 20px;

	&.core-value {
		padding: 20px;
		border-bottom: 1px solid;
	}

	@media (min-width: 1024px) {
		font-size: 48px;
		padding-bottom: 50px;
		&.core-value {
			padding: 50px;
			width: 50%;
			border: none;
		}
	}
`;

const ValuesList = styled.ul`
	width: 100%;
	padding: 0;
	margin: 0 0 -1px;

	@media (min-width: 1024px) {
		width: 50%;
		border-left: 1px solid;
		display: flex;
		flex-wrap: wrap;
	}
`;

const Value = styled.li`
	border-bottom: 1px solid;
	padding: 20px;
	list-style: none;

	@media (min-width: 1024px) {
		padding: 50px 20px;
		width: 50%;
		&:nth-of-type(2n + 1) {
			border-right: 1px solid;
		}
	}
`;

const ValueTitle = styled.p`
	font-size: 20px;
	padding-bottom: 15px;

	@media (min-width: 1024px) {
		font-size: 32px;
		padding-bottom: 25px;
	}
`;

const ValueDesc = styled.p``;

const Bio = styled.section`
	padding: 20px;

	@media (min-width: 1024px) {
		padding: 50px;
		p {
			font-size: 32px;
		}
	}
`;

const IGLink = styled.a`
	margin-top: 20px;
	display: block;

	svg {
		width: 24px;
	}

	@media (min-width: 1024px) {
		svg {
			width: 36px;
		}
	}
`

const About = () => {
	const data = useStaticQuery(graphql`
		{
			allSanityAbout {
				nodes {
					image {
						asset {
							url
							fluid {
								base64
								aspectRatio
								src
								srcSet
								srcWebp
								srcSetWebp
								sizes
							}
						}
					}
					missionStatement
					coreValues {
						title
						description
						_key
					}
					_rawBio
				}
			}
		}
	`);

	const { image, missionStatement, coreValues, _rawBio } = data.allSanityAbout.nodes[0];

	return (
		<React.Fragment>
			<SEO title='About' />
			<AboutPage>
				<AboutHeader>
					{image && (
						<AboutImg>
							<picture>
								<source srcSet={`${image.asset.url}?w=1600&auto=format&q=75`} media='(min-width: 1000px)' />
								<source srcSet={`${image.asset.url}?w=1000&auto=format&q=75`} media='(min-width: 600px)' />
								<img alt='Marquise Stillwell' src={`${image.asset.url}?w=400&auto=format&q=75&fit=crop`} />
							</picture>
						</AboutImg>
					)}
					{missionStatement && (
						<MissionStatement>
							<Statement>{missionStatement}</Statement>
						</MissionStatement>
					)}
				</AboutHeader>

				{coreValues.length > 0 && (
					<CoreValues>
						<Label className='core-value'>Core Values</Label>
						<ValuesList>
							{coreValues.map((value, index) => (
								<Value key={index}>
									<ValueTitle>{value.title}</ValueTitle>
									<ValueDesc>{value.description}</ValueDesc>
								</Value>
							))}
						</ValuesList>
					</CoreValues>
				)}

				{_rawBio && (
					<Bio>
						<Label>Bio</Label>
						<BlockContent blocks={_rawBio} />
						<IGLink href='https://www.instagram.com/quisenyc/' target='_blank' rel='noreferrer noopener'>
							<IconInstagram />
						</IGLink>
					</Bio>
				)}
			</AboutPage>
		</React.Fragment>
	)
};

export default About;
